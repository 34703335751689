@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.tracking-most-recent-status tr:first-child {
  font-weight: bold;
  font-size: 15px;
}

.tracking-table {
  margin-bottom: 20px !important;
}

.menu {
  overflow-x: auto;
  overflow-y: hidden;
}

header {
  padding: 0 !important;
}

/* ----------------- Titles CONTEOS -------------------------- */

.Conteo-Criticidad-title,
.Conteo-Envases-title,
.Conteo-Pallets-title {
  color: var(--gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
}

.Conteo-Criticidad-title-strong,
.Conteo-Envases-title-strong,
.Conteo-Pallets-title-strong {
  color: var(--gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

/* ----------------------- LOGIN  -------------------- */

.container-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* LEFT LOGIN */

.left-login {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.container-left-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 246.999px;
  height: 150px;
  flex-shrink: 0;
}

.left-row-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.title-left-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
}

.title-left-div-Login {
  display: flex;
  align-items: center;
  padding: 0px;
  height: 35px;
  width: 100%;
}

.title-left-div-gestion {
  display: flex;
  align-items: center;
  padding: 0px;
  height: 35px;
  width: 100%;
}

.title-left-div-inventario {
  display: flex;
  align-items: center;
  padding: 0px;
  height: 30px;
  width: 100%;
}

.left-row-img {
  width: 76.002px;
  height: 105px;
  flex-shrink: 0;
}
.left-container-title-text {
  background-color: red;
  height: 100%;
  display: block;
  flex-direction: column;
}

.left-title-login {
  color: rgba(100, 167, 11, 0.4);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.left-title-gestion {
  color: #64a70b;
  font-family: Roboto Slab;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.left-title-inventario {
  color: #64a70b;
  font-family: Roboto Slab;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* LEFT LOGIN */

/* RIGHT LOGIN */
.right-login {
  height: 100%;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #64a70b;
  flex-shrink: 0;
  position: relative;
}

.vector-CCU {
  position: absolute;
  bottom: 0;
  right: 1px;
}

.ccu-logo-login {
  width: 111.176px;
  height: 41.81px;
  flex-shrink: 0;
  position: absolute;
  bottom: 27px;
  right: 20px;
  z-index: 2;
}

/* ------------------ FORM LOGIN ------------------------*/
.form-login {
  height: 400px;
  width: 452px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 30px;
  margin: 0px auto;
  padding: 15px;
}

/* --------------------- FORM LOGIN ---------------------*/

.container-form-login {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

/* ---------------INPUT EMAIL-------- */

.login-user-email {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-input-username {
  background-color: transparent;
  height: 43px;
  border-radius: 5px;
  border: 1px solid #fff;
  outline: none;
  padding-left: 10px;
  color: white;
}
.label-input-username {
  color: white;
  margin-left: 5px;
}

.login-input-username::placeholder {
  color: white;
}

/* ---------------INPUT EMAIL-------- */

/* ---------------INPUT PASSWORD-------- */

.login-user-password {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.login-password {
  background-color: transparent;
  width: 100%;
  height: 43px;
  border-radius: 5px;
  border: 1px solid #fff;
  outline: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-input-password {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}

.visibility-login-password {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.rdt_TableHeadRow {
  background-color: #f2f2f2 !important;
}
.label-input-password {
  color: white;
  margin-left: 5px;
}

.login-input-password::placeholder {
  color: white;
}
/* ---------------INPUT PASSWORD-------- */
/* ----------  BUTTON LOGIN -----------*/

.login-user-button {
  display: flex;
  width: 100%;
  height: 43px;
  justify-content: flex-end;
}

/* ----------  BUTTON -----------*/

.button-login {
  width: 137px;
  height: 43px;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #fff;
}

.button-login:hover {
  background-color: white;
  color: rgb(142, 139, 139);
  transition: 0.5s all ease;
}
/* RIGHT LOGIN */

/* ----------  BUTTON -----------*/

/* --------------- LOGIN -----------------*/

@media screen and (min-width: 680px) and (max-width: 1280px) {
  /* FORM LOGIN */
  .form-login {
    height: 300px;
    width: 400px;
  }

  .login-input-username {
    height: 34px;
  }

  .login-password {
    height: 34px;
  }

  .login-user-button > .button-login {
    height: 38px;
  }
  /* FORM LOGIN */
}

/* ---------------------DATA TABLE ------------------ */
.title-DataTable {
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
/* ---------------------DATA TABLE ------------------ */

/* ------------------------POP UP GENERAR CONTEO----------------------- */

.pop-up-generar-conteo-title {
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
}
.pop-up-generar-conteo-title-strong {
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.pop-up-container-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop-up-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 25vw;
  gap: 15px;
}

.btn-pop-up-generic {
  width: 252px;
  padding: 12px 20px;
  background: transparent;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #64a70b;
  color: #64a70b;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn-pop-up-generic:hover {
  background: #64a70b;
  color: white;
  transition: all 0.2s ease;
}

.react-date-picker__wrapper {
  border: none !important;
}

.inputDateGlobal {
  border-radius: 5px;
  border: 1px solid var(--Gray-2, #4f4f4f);
  display: flex;
  flex-grow: 1;
  width: 252px;
  flex-shrink: 0;
  display: inline-flex;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
}

.pop-up-container-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pop-up-date-title {
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

/* ------------------------POP UP GENERAR CONTEO----------------------- */

/* ----------------------- SPINNER---------------------------- */

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #64a70b;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ----------------------- SPINNER---------------------------- */

.btn-delete-general {
  color: var(--Gray-3, #ffffff);
  text-align: right;
  line-height: normal;
  text-transform: uppercase;
  background-color: rgb(240, 78, 78);
  border-radius: 9px;
  padding: 6px 15px;
  border: none;
}

input[type='checkbox'],
input[type='radio'] {
  width: 20px !important;
  height: 20px !important;
}

/* INGRESO MANUAL BUTON */

.btn-ingreso-manual {
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: var(--ccu-celeste, #007dba);
}

.btn-delete-general:hover {
  background-color: #dad4d4;
  line-height: 24px;
  color: black;
}

/************** generic new table **********************/
.generic-new-table-container {
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.generic-new-table-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.generic-new-table-title {
  width: 50%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
  text-transform: uppercase;
  margin: 0;
}

.generic-new-table-actions-container {
  width: 50%;
  display: flex;
  gap: 1vw;
  align-items: center;
  justify-content: flex-end;
}
